jQuery(document).ready(function( $ ) {

  // dropdown wrapper
  $('ul.sub-menu').wrap('<div class="pc-dropdown"></div>');

  $('.pc-bars').on('click',function(e){
    e.preventDefault();
    $('header.pc-nav').toggleClass('open');
    $('body').toggleClass('pc-frozen');
  });

  $('input').on('blur',function(){

    if ( $(this).hasClass('wpforms-valid') ) {

      $(this).parent('div').addClass('before-yellow');

    }

  });

  AOS.init();





});
